import { Body, vars } from "@casavo/habitat"
import { motion } from "framer-motion"
import useTranslation from "next-translate/useTranslation"
import { FC, PropsWithChildren, useRef, useState } from "react"

import { ArrowLeft } from "@app/shared/components/icons/ArrowLeft"
import { ArrowRight } from "@app/shared/components/icons/ArrowRight"
import { MyImage } from "@app/shared/components/MyImage"
import { sprinkles } from "@app/shared/design-lib/style-theme/sprinkles.css"
import { useChangeBgColorInView } from "@app/shared/hooks/useChangeBgColorInView"
import { useFadeInTransition } from "@app/shared/hooks/useFadeInTransition"
import { SellPress } from "@app/shared/types/website-pages"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import {
  homeCarousel,
  homeCarouselContent,
  homeCarouselElement,
  homePress,
  homePressArrow,
  homePressImage,
  homePressImageWrapper,
  imageWrapper,
} from "./styles.css"

export const HomePress: FC = () => {
  const { lang, t } = useTranslation("sell")
  const pressItems = t<SellPress>("sell:press", {}, { returnObjects: true }).filter(
    ({ description, image }) => description && image
  )
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const targetRef = useRef(null)
  const { opacity, y } = useFadeInTransition({ fadeInDistance: 64, targetRef })
  const imageHeight = lang === "it" ? "small" : "medium"

  const arrowClick = (direction: "left" | "right") => {
    const nextIndex = direction === "left" ? currentItemIndex - 1 : currentItemIndex + 1
    setCurrentItemIndex(nextIndex < 0 ? pressItems.length - 1 : nextIndex % pressItems.length)
  }

  useChangeBgColorInView(targetRef, vars.colors.background.default.light, { amount: "all" })

  return (
    <motion.section ref={targetRef} className={homePress} style={{ opacity, y }}>
      <ArrowLeft
        className={homePressArrow}
        onClick={() => {
          arrowClick("left")
          handleUserEventClick("CarouselPress-ClickOnPreviousArrow")
        }}
      />
      <div className={homeCarousel}>
        <ul className={homeCarouselContent} style={{ transform: `translateX(-${currentItemIndex * 100}%)` }}>
          {pressItems.map(({ description, image, link }, index) => {
            return (
              <li key={index} className={imageWrapper}>
                <WithLink link={link}>
                  <div className={homeCarouselElement}>
                    <div className={homePressImageWrapper[imageHeight]}>
                      <MyImage
                        fill
                        alt={description}
                        className={homePressImage}
                        src={`/images/press/logos/${image}.webp`}
                      />
                    </div>
                    <Body size="m">{description}</Body>
                  </div>
                </WithLink>
              </li>
            )
          })}
        </ul>
      </div>
      <ArrowRight
        className={homePressArrow}
        onClick={() => {
          arrowClick("right")
          handleUserEventClick("CarouselPress-ClickOnNextArrow")
        }}
      />
    </motion.section>
  )
}

const WithLink = ({ children, link }: PropsWithChildren<{ link?: string }>) => {
  return !!link ? (
    <a
      className={sprinkles({
        display: "block",
        height: "100%",
      })}
      href={link}
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </a>
  ) : (
    <>{children}</>
  )
}
